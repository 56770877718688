<template>
  <Transition>
    <div v-if="$store.state.form.display" class="pform">
      <btn-icon
        icon="delete"
        size="2"
        class="pform__close"
        @click="$store.commit('hideForm')"
      ></btn-icon>
      <div class="pform__content">
        <h2>{{store.state.form.instructions.title ? store.state.form.instructions.title : 'Missing title'}}</h2>
        <div class="pform__form">
          <form-generator
            :form="form"
            :key="formVersion"
            @formData="handleFormData"
          ></form-generator>
          <Btn :loading="isLoading" @click="submitForm">
            {{
              store.state.form.instructions.cta.label
                ? store.state.form.instructions.cta.label
                : "send"
            }}</Btn
          >
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup>
import FormGenerator from "@/components/form/FormGenerator.vue";
import Btn from "@/components/utils/Btn.vue";
import BtnIcon from "@/components/utils/BtnIcon.vue";
import { ref, watch } from "vue";
import { useStore } from "vuex";

import { getData } from "@/composables/api/request";

const formData = ref({});
const formVersion = ref(0);
const store = useStore();
const isLoading = ref(false);
const form = ref([]);

watch(
  () => store.state.form.refreshVersion,
  async () => {
    await createForm();
  }
);

/**
 * The form is created thanks to the model defined into the API.
 */
const createForm = async () => {
  const instructions = store.state.form.instructions;

  if(instructions.form){
    form.value = instructions.form;
    return;
  }

  if (instructions.dataFetch && instructions.dataFetch.url) {
    const res = await getData({ url: instructions.dataFetch.url });
    instructions.data = res;
  }

  //Form from model
  if (instructions.model.url) {
    const model = await getData({ url: instructions.model.url });
    

    form.value = [];
    for (let key of Object.keys(model)) {
      const fields = {};
      if (!model[key].metadata) {
        for (let subkey of Object.keys(model[key])) {
          if (!model[key][subkey].metadata) continue;
          fields[`${key}.${subkey}`] = model[key][subkey].metadata.form;
        }
      } else {
        fields[key] = model[key].metadata.form;
      }

      for (let k of Object.keys(fields)) {
        if (instructions.type === "create"){
          if(!fields[k].create) continue;
        } 
        else if (instructions.type === "edit"){
          if(!fields[k].editable) continue;
        } 
        else if  (!fields[k][instructions.type]) continue;
    



        //Fetch Select Fields
        let options = false;
        if(fields[k].type === "select" &&fields[k].url){
          options = [{
            label:fields[k].placeholder,
            selected: true,
            disabled:true
          }];
          const selects = await getData({url: fields[k].url});

          for(let o of selects){
            const op = {
              label:o.name,
              value:o._id
            }
            options.push(op)
          }
        }


        const obj = {
          name: k,
          label: fields[k].label,
          type: fields[k].type,
          placeholder: fields[k].placeholder
            ? fields[k].placeholder
            : fields[k].label,
          required: fields[k].required,
          options
        };
        form.value.push(obj);
      }
    }
    formVersion.value++;
  }

  ///////////////////////////////////////////////////////
  //If data is already set for the customer,
  if (instructions.data && Object.keys(instructions.data).length) {
    form.value.map((el) => {

      if (el.name.includes(".")) {
        el.value =
          instructions.data[el.name.split(".")[0]] &&
          instructions.data[el.name.split(".")[0]][el.name.split(".")[1]]
            ? instructions.data[el.name.split(".")[0]][el.name.split(".")[1]]
            : null;
      } else if(el.type === 'select'){
        el.value = instructions.data[el.name]._id;
      }else {
        el.value = instructions.data[el.name]
          ? instructions.data[el.name]
          : null;
      }

      return el;
    });
  }
};

const handleFormData = (val) => {
  formData.value = val.data;
};

const submitForm = async () => {
  const instructions = store.state.form.instructions;

  //Check for required fields when we create new entry
  if (instructions.type === "create") {
    const requiredFields = form.value.filter((el) => el.required);

    for (let field of requiredFields) {
      if (!formData.value[field.name]) {
        store.dispatch("notification", {
          message: `The field ${field.label} is missing`,
          color: "red",
        });
        return;
      }
    }
  }

  isLoading.value = true;

  let valid = false;
  if (instructions.actionArgs) {
    valid = await instructions.action(formData.value, instructions.actionArgs);
  } else {
    valid = await instructions.action(formData.value);
  }

  isLoading.value = false;

  if (valid) {
    store.commit("formCompleted");
    store.dispatch("notification", {
      message: "formulaire envoyé avec succès",
      color: "green",
    });
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/main";
.pform {
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 0;
  width: 50vw;
  height: 100vh;
  background-color: $color-grey-05;
  box-shadow: 0.3rem 0rem 1rem rgba($color-black, 0.6);
  padding: 2rem;
  overflow-y: scroll;

  &__content {
    margin-top: 5rem;
    & h2 {
      margin-bottom: 2rem;
    }
  }

  &__close {
    position: absolute;
    top: 2rem;
    left: 2rem;
  }
}

.v-enter-active,
.v-leave-active {
  transition: all 0.4s ease;
}

.v-enter-from,
.v-leave-to {
  // top:0;
  // right:-50vw;
  transform: translateX(50vw);
  // opacity: 0;
}
</style>
